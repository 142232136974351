import React from 'react';
import {Grid} from '@material-ui/core';
import SecondarySkyline from './../../assets/secondary_skyline.svg';
import PageSection from '../../components/PageSection/PageSection';
import ContactInput from '../../components/ContactInput/ContactInput';
import withViewportAnimation from './../../components/ViewportAnimation/ViewportAnimation';
import ContactStyle from './ContactStyle';

const ContactContent = {
  action: 'https://formspree.io/xezlnvkm', // hallo@taktikal.is
  title: 'Wana try it?',
  content: 'Are you intrigued? Send us a line if you want to try our product and and we will get back with you reply to you as soon as possible',
  placeholder: 'Your email',
  submit: 'Send',
  errorMessage: 'Check your email and try again'
}

const ContactTextBlock = () => 
  <React.Fragment>
    <div className="title">{ContactContent.title}</div>
    <div className="content">{ContactContent.content}</div>
  </React.Fragment>

class Contact extends React.Component {
  state = { error: false, sent: false, response: '', value: '' };

  constructor(props) {
    super(props);
    this.form = null;
  }

  sendEmail = (e) => {
    let data = new FormData();
    data.append('_replyto', this.state.value);
    data.append('email', this.state.value);

    this.setState({ error: false, response: '' });
    fetch(ContactContent.action, {
        method: "POST", 
        headers: { "Accept": "application/json" },
        body: data
    })
    .then(response => response.json())
    .then(response => {
      if (response.success) this.setState({ sent: true, response: response.success });
      if (response.error) this.setState({ sent: false, error: true, response: ContactContent.errorMessage });
    })
    .catch(() => {
      this.setState({ sent: false, error: true, response: 'Internet connection error' })
    });
    
    e.preventDefault();
  }

  onValueChange(val) {
    this.setState({ value: val });
    if (this.state.error || this.state.sent) this.setState({ sent: false, error: false });
  }
  
  render() {
    const {sent, error, response} = this.state;
    return (
      <ContactStyle>
        <PageSection secondary background={SecondarySkyline}>
          <Grid container direction="column" justify="center" alignItems="center">
            <Grid item xs={12} md={6} className="text-block">
              {
                withViewportAnimation(ContactTextBlock, { direction: 'vertical', from: 'top' })(this.props)
              }
            </Grid>
            <Grid item xs={12}>
              <form action={ContactContent.action} method="POST" onSubmit={(e) => this.sendEmail(e)}>
                <ContactInput 
                  error={error} 
                  errorMessage={response}
                  success={sent}
                  onChange={(val) => this.onValueChange(val)} 
                  placeholder={ContactContent.placeholder} 
                  submitText={ContactContent.submit}>
                </ContactInput>
              </form>
            </Grid>
          </Grid>
        </PageSection>
      </ContactStyle>
    )
  }
}

export default Contact;
