import React from 'react';
import {Grid} from '@material-ui/core';
import PrimarySkyline from './../../assets/primary_skyline.svg';
import ClockIcon from './../../assets/icons/clock.svg';
import SecurityIcon from './../../assets/icons/security.svg';
import EarthIcon from './../../assets/icons/earth.svg';
import TickIcon from './../../assets/icons/tick.svg';
import PageSection from '../../components/PageSection/PageSection';
import withViewportAnimation from './../../components/ViewportAnimation/ViewportAnimation';
import TextBlock from './../../components/TextBlock/TextBlock';
import AboutStyle from './AboutStyle';

const AboutTextBlock = {
  title: 'About',
  header: 'How DropAndSign benefits you',
  content: 'Drop and sign is a secure soulution suitable for any buissness that send documents for signatures.'
}

const AboutContentBlocks = [
  {
    id: 0,
    icon: ClockIcon,
    title: 'Time efficient',
    content: 'It only takes around 3 minutest to send and sign documents with our solution, vs. 3 days the old way.'
  },
  {
    id: 1,
    icon: SecurityIcon,
    title: 'Secure',
    content: 'We meet very strict requirements under eIDAS regulation. Witch makes the signatures equal to a handwritten one.'
  },
  {
    id: 2,
    icon: TickIcon,
    title: 'Easy',
    content: 'We put an emphasis on a good and simple interface so the product will be easy to use and sutable fo both your client and you'
  },
  {
    id: 3,
    icon: EarthIcon,
    title: 'Eco',
    content: 'Drop and sign is a secure soulution sutable for any buissness that send documents for signatures.'
  },
]

const AboutBlock = props =>
  <React.Fragment>
    <div className="header-wrap">
      <Grid container justify="center" alignItems="center" className="icon">
        <img src={props.icon} alt=""/>
      </Grid>
      <div className="title">{props.title}</div>
    </div>
    <div className="content">{props.content}</div>
  </React.Fragment>

const About = props => 
  <AboutStyle>
    <PageSection alignItems="flex-start" background={PrimarySkyline}>
      <Grid item xs={12} md={6} lg={6}>
        <TextBlock
          className="text"
          font="large"
          title={ AboutTextBlock.title }
          header={ AboutTextBlock.header }
          content={ AboutTextBlock.content }
        ></TextBlock>
      </Grid>
      <Grid container item xs={12} lg={6}>
        {
          AboutContentBlocks.map(block =>
            <Grid container item xs={12} md={6} lg={6} direction="column" className="content-block" key={block.title}>
              {
                withViewportAnimation(AboutBlock, {from: 'right', delay: (block.id % 2 === 0 ? 0 : 0.2)})(block)
              }
            </Grid>
          )
        }
      </Grid>
    </PageSection>
  </AboutStyle>

export default About;
