import styled from 'styled-components';

const ComingSoon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .background-shadow {
    position: fixed;
    left: 0; bottom: 0px;
    width: 100%; height: 100%;
    background: url(${props => props.backgroundShadow}) center bottom no-repeat;
    background-size: cover;
  }
  .background {
    position: fixed;
    left: 0; bottom: -40px;
    width: 100%; height: 100%;
    background: transparent url(${props => props.background}) 10% bottom no-repeat;
  }

  .content {
    position: relative;
    height: 100%;
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header, .subheader {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 600;
    color: #4D20FF;
  }

  .header {
    font-size: 35px;
  }

  .subheader {
    font-size: 45px;
    margin-bottom: 200px;
  }
  @media (max-width: 1120px) {
    .header {
      font-size: 25px;
    }
  
    .subheader {
      font-size: 30px;
      margin-bottom: 100px;
    }

    .content {
      margin: 0 20px;
    }
  }
`;

export default ComingSoon;
