import styled from 'styled-components';

const ActionIconStyle = styled.div`
  width: 88px;
  height: 88px;
  position: relative;

  .background-circle {
    background: ${props => props.theme.color.iconBackground};
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @media (max-width: ${props => props.theme.content.container.large}px) {
    transform: scale(0.75);
  }
`;

export default ActionIconStyle;
