import styled from 'styled-components';

const ViewportAnimationStyle = styled.div`
  transition-duration: ${props => props.delay ? props.delay : 0.3}s;
  transition-delay: ${props => props.delay ? props.delay : 0}s;
  
  &.visible {
    transition-timing-function: cubic-bezier(.37,.61,.39,1.24);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  &.hidden {
    transition-timing-function: cubic-bezier(1,.4,.44,.84);
    opacity: 0;
    
    &.horizontal {
      transform: translate3d(
        ${ props => props.from === 'right' ? '' : '-'}${ props => props.offset ? props.offset : 50 }px, 0, 0
      );
    }
    &.vertical {
      transform: translate3d(
        0, ${ props => props.from === 'top' ? '-' : ''}${ props => props.offset ? props.offset : 50 }px, 0
      );
    }
  }

  @media (max-width: ${props => props.theme.mobileBreak}px) {
    &.hidden {
      &.horizontal {
        transform: translate3d(
          ${ props => props.offset ? props.offset : 50 }px, 0, 0
        );
      }
    }
  }
`;

export default ViewportAnimationStyle;
