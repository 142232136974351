import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import StyledNavigation from './NavigationStyle';

const Link = props => 
  <a onClick={(e) => props.onClick(e)}
    href={props.href} 
    target={props.target}
    className={ 
      (props.primary ? ' primary ' : props.secondary ? ' secondary ' : '') + 
      (props.active ? ' active ' : '') + ' link'
    } >
    {props.children}
  </a>

const MenuLinks = props => props.menuItems && props.menuItems.map(item => 
  <Link 
    active={item.active}
    onClick={(e)=>props.onClick(e, item.order)} 
    key={item.order} 
    href={item.link} 
    target={item.target} 
    primary={item.primary} 
    secondary={item.secondary}>
    {item.title}
  </Link>
)

const Menu = props =>
  <StyledNavigation className={props.open ? 'open' : ''}>
    <Grid container alignItems="center" className="menu">
      <MenuLinks menuItems={props.menuItems} onClick={props.onClick}></MenuLinks>
    </Grid>
    <Grid container direction="column" className={(props.open ? 'open' : '') + ' mobile-menu'}>
      <MenuLinks menuItems={props.menuItems} onClick={props.onMobileItemClick}></MenuLinks>
    </Grid>
    <div 
      onClick={props.onMobileMenuIconClick}
      className={(props.open ? 'open' : '') + ' mobile-menu-icon'}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </StyledNavigation>

class Navigation extends React.Component {
  state = { mobileOpen: false };

  render() {
    const { mobileOpen } = this.state;
    const {menuItems, onMenuClick} = this.props; 
    return (
      <Menu 
        menuItems={menuItems} 
        onClick={(e, order) => onMenuClick(e, order)}
        onMobileItemClick={(e, order) => {
          this.setState({ mobileOpen: false });
          onMenuClick(e, order);
        }}
        onMobileMenuIconClick={() => this.setState({ mobileOpen: !mobileOpen })}
        open={mobileOpen}
      ></Menu>
    )
  }
}

export default withRouter(Navigation);
