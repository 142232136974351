import React from 'react';
import {Grid} from '@material-ui/core';
import SecondarySkyline from './../../assets/secondary_skyline.svg';
import {ActionIconTypes} from '../../components/ActionIcon/ActionIcon';
import TextBlock from '../../components/TextBlock/TextBlock';
import PageSection from '../../components/PageSection/PageSection';
import Instructions from '../../components/Instructions/Instructions';
import HowItWorksStyle from './HowItWorksStyle';

const TextBlockContent = {
  title: 'How it works',
  header: 'Only three simple steps',
  content: 'Drop and sign has two solutions one for you and your staff, and one for your client'
}

const InstructionsData = {
  client: {
    header: 'For your independent client',
    steps: [
      {
        id: 0,
        active: false,
        icon: ActionIconTypes.drop,
        title: '1. Drop a document',
        content: 'Choose a document to send to your client and drop it in our product.'
      },
      {
        id: 2,
        active: false,
        icon: ActionIconTypes.send,
        title: '2. Send to client',
        content: 'Next send the document to the client. Lorem ipsum dolor sit imen'
      },
      {
        id: 4,
        active: false,
        icon: ActionIconTypes.sign,
        title: '3. Client signs',
        content: 'Then last the client gets the document via email, signs with his electronice signature and your done'
      },
    ],
  },
  staff: {
    header: 'For you and your staff',
    steps: [
      {
        id: 1,
        active: false,
        icon: ActionIconTypes.pick,
        title: '1. Pick document',
        content: 'The clients picks a document to sign from your buisnesses webpage'
      },
      {
        id: 3,
        active: false,
        icon: ActionIconTypes.fill,
        title: '2. Fill document',
        content: 'The client fills out the document using our backend product'
      },
      {
        id: 5,
        active: false,
        icon: ActionIconTypes.sign,
        title: '3. Client signs',
        content: 'Then lastly he clients signs with his electronic signature and is done.'
      },
    ],
  }
}

class HowItWorks extends React.Component {
  state = { items: InstructionsData };

  onItemMouseClick(id) {
    this.toggleItemState(id);
  }

  onItemMouseEnter(id) {
    this.toggleItemState(id);
  }
  
  onItemMouseLeave(id) {
    this.toggleItemState(id);
  }

  toggleItemState(id) {
    this.setItemActiveState(item => item.active = item.id === id && !item.active);
  }

  setItemActiveState(validationCallback) {
    let newState = { items: this.state.items };
    newState.items.client.steps.forEach(validationCallback)
    newState.items.staff.steps.forEach(validationCallback)
    this.setState(newState); 
  }

  render() {
    const clientItems = this.state.items.client;
    const staffItems = this.state.items.staff;
    return (
      <HowItWorksStyle>
        <PageSection secondary background={SecondarySkyline}>
          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <TextBlock light
                font="medium"
                title={ TextBlockContent.title }
                header={ TextBlockContent.header }
                content={ TextBlockContent.content }
              ></TextBlock>
            </Grid>
          </Grid>
          <Instructions 
            onItemMouseClick={(id) => this.onItemMouseClick(id)}
            onItemMouseEnter={(id) => this.onItemMouseEnter(id)}
            onItemMouseLeave={(id) => this.onItemMouseLeave(id)}
            client={clientItems} staff={staffItems}
          >
          </Instructions>
        </PageSection>
      </HowItWorksStyle>
    )
  }
}

export default HowItWorks;
