import styled from 'styled-components';

const MainStyle = styled.div`
  position: relative;
  overflow: hidden;
  .page-section {
    padding: 70px 0;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }

  @media (max-width: ${props => props.theme.content.container.large}px) {
    .image-wrap {
      padding-left: 10px;
    }
    .image {
      padding-left: 0px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  @media (max-width: 960px) {
    .image-wrap {
      padding-left: 0px;
      margin-top: -50px;
      height 300px;
    }
    .image {
      padding-left: 0px;
      left: 50%; top: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.6);
    }
    .page-section {
      padding: 25px 0;
      padding-top: 10px;
    }
  }

  .background-shadow {
    position: absolute;
    left: 0; bottom: 0;
    width: 100%; height: 100%;
    z-index: 1;

    .background {
      position: absolute;
      left: 0; bottom: 0;
      width: 100%; height: 100%;
      z-index: 1;
      background: url(${props => props.backgroundShadow}) center bottom no-repeat;
      background-size: cover;
    }
  }
`;

export default MainStyle;
