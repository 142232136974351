import styled from 'styled-components';

const PrivacyStyle = styled.div`
  color: ${props => props.theme.color.darkText};

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  h1 { font-size: 34px; margin-bottom: 40px; }
  h2 { font-size: 28px; margin-bottom: 30px; }
  h3 { font-size: 21px; margin-bottom: 30px; }
  h4 { font-size: 18px; }
  h5 { font-size: 16px; }
  h6 { font-size: 14px; }

  p, li {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5em;
    font-weight: 300;
  }

  ul {
    list-style-type: disc;
    & ul {
      list-style-type: circle;
    }
  }

  ul, ol {
    margin-left: -1.2em;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    margin: 50px 0px;
  }

  th {
    font-weight: 300;
    text-align: left;
  }

  td, th {
    vertical-align: baseline;
    padding: 20px 10px;
    border: 1px solid #000000;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.color.primary};
    transition: .3s ease-in-out;
    &:hover {
      color: ${props => props.theme.color.primaryLight};
    }
  }

  .text-center {
    text-align: center;
  }

  .privacy-section {
    padding: 50px 0px;
  }

  @media (max-width: 768px) {
    h1 { font-size: 28px; margin-bottom: 20px; }
    h2 { font-size: 21px; margin-bottom: 10px; }
    h3 { font-size: 18px; margin-bottom: 5px }
    h4 { font-size: 16px; }
    h5 { font-size: 14px; }
    h6 { font-size: 12px; }

    table {
      table-layout: auto;
      margin: 20px 0px;
    }
    
    td, th {
      padding: 10px 10px;
    }

    .privacy-section {
      padding: 20px 0px;
    }
  }
`;

export default PrivacyStyle;
