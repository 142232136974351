import styled from 'styled-components';

const NavigationStyle = styled.nav`
  position: relative;
  & .link {
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.1em;
    color: ${ props => props.theme.color.primary };
    margin: 0 20px;
    transition: color
    ${ props => props.theme.transition.normal } 
    ${ props => props.theme.transition.decelerate };
    
    &:hover:not(.primary):not(.secondary), &.active {
      color: ${ props => props.theme.color.menuActive };
    }
    
    color: ${ props => props.theme.color.primary };
    text-decoration: none;

    &.primary, &.secondary {
      margin: 0 5px;
      border-radius: 4px;
      padding: 9px 20px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      transition: box-shadow
        ${ props => props.theme.transition.normal } 
        ${ props => props.theme.transition.decelerate };
      &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      }
    }

    &:nth-last-child(1) {
      margin-right: 0px;
    }

    &.primary {
      color: ${ props => props.theme.color.lightText };
      background: ${ props => props.theme.color.primary };
    }

    &.secondary {
      color: ${ props => props.theme.color.primary };
      background: ${ props => props.theme.color.signupBackground };
    }
  }

  
  .mobile-menu-icon {
    display: none;
    width: 30px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 100%;
      background: ${props => props.theme.color.primary};
      border-radius: 5px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
    }
    span:nth-child(1) {
      top: 0px;
    }
    span:nth-child(2), span:nth-child(3) {
      top: 10px;
    }
    span:nth-child(4) {
      top: 20px;
    }
    
    &.open {
      span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
      span:nth-child(2) {
        transform: rotate(45deg);
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
      span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }

  .mobile-menu {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: #FFFFFF;
    padding-top: 10px;
    transform: translateX(100%);
    transition: .25s ease-in-out;
    
    &.open {
      transform: translateX(0%);
    }

    .link {
      font-size: 24px;
      margin: 10px 30px;
      &.primary, &.secondary {
        margin: 10px 10px;
      }
    }
  }

  @media (max-width: ${props => props.theme.mobileBreak}px) {
    .mobile-menu-icon {
      display: block;
    }
    .menu {
      display: none;
    }
  }
`;

export default NavigationStyle;
