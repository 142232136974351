import React from 'react';
import {Grid} from '@material-ui/core';
import TextBlock from './../../components/TextBlock/TextBlock';
import PageSection from './../../components/PageSection/PageSection';
import MainShadow from './../../assets/main_shadow.svg';
import MainSkyline from './../../assets/main_skyline.svg';
import MainProcessIcon from './../../components/ActionIcon/MainProcessIcon/MainProcessIcon';
import MainStyle from './MainStyle';

const Content = {
  title: 'Lorem ipsum dolor sit',
  header: 'A simple and safe solution for your bussines',
  content: 'Drop and sign is a secure soulution sutable for any buissness that send documents for signature.'
}

const Main = props => 
  <MainStyle backgroundShadow={MainShadow}>
    <div className="background-shadow">
      <div className="background"></div>
    </div>
    <PageSection className="page-section" spacing={32} background={MainSkyline}>
      <Grid item xs={12} md={6}>
        <TextBlock alignRight
          className="text"
          font="large"
          delay={0.3}
          title={ Content.title }
          header={ Content.header }
          content={ Content.content }
        ></TextBlock>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="image-wrap">
          <MainProcessIcon className="image"></MainProcessIcon>
        </div>
      </Grid>
    </PageSection>
  </MainStyle>

export default Main;
