import React from 'react';
import {Power4, Elastic, TimelineMax} from "gsap/TweenMax";
import SVG from 'react-inlinesvg';
import StyledMainProcessIcon from './MainProcessIconStyle';
import MouseCursorIconSrc from './../../../assets/partials/MainProcess/mouse-cursor.svg';
import DocumentTickIconSrc from './../../../assets/partials/MainProcess/document-tick.svg';
import DocumentIconSrc from './../../../assets/partials/MainProcess/document.svg';
import PhoneIconSrc from './../../../assets/partials/MainProcess/phone-icon.svg';
import PhoneShadowSrc from './../../../assets/partials/MainProcess/phone-shadow.svg';
import SecureNotificationSrc from './../../../assets/partials/MainProcess/secure-notification.svg';
import SecureCircleIconSrc from './../../../assets/partials/MainProcess/secure-circle-icon.svg';
import SecureIconSrc from './../../../assets/partials/MainProcess/secure-icon.svg';
import KeyboardIconSrc from './../../../assets/partials/MainProcess/keyboard.svg';
import CursorIconSrc from './../../../assets/partials/MainProcess/cursor.svg';

const DocumentIcon = props => <SVG src={DocumentIconSrc} onLoad={props.onLoad}></SVG>
const MouseCursorIcon = props => <SVG src={MouseCursorIconSrc} onLoad={props.onLoad}></SVG>
const DocumentTickIcon = props => <SVG src={DocumentTickIconSrc} onLoad={props.onLoad}></SVG>
const PhoneIcon = props => <SVG src={PhoneIconSrc} onLoad={props.onLoad}></SVG>
const PhoneShadow = props => <SVG src={PhoneShadowSrc} onLoad={props.onLoad}></SVG>
const SecureCircleIcon = props => <SVG src={SecureCircleIconSrc} onLoad={props.onLoad}></SVG>
const SecureNotification = props => <SVG src={SecureNotificationSrc} onLoad={props.onLoad}></SVG>
const SecureIcon = props => <SVG src={SecureIconSrc} onLoad={props.onLoad}></SVG>
const KeyboardIcon = props => <SVG src={KeyboardIconSrc} onLoad={props.onLoad}></SVG>
const CursorIcon = props => <SVG src={CursorIconSrc} onLoad={props.onLoad}></SVG>

class MainProcessIcon extends React.Component {
  state = { active: false, loadedCount: 0 };

  constructor(props) {
    super(props);
    this.mounted = false;
    this.assetsCount = 10;
    this.background = null;
    this.document = null;
    this.documentTick = null;
    this.mouseCursor = null;
    this.phone = null;
    this.phoneShadow = null;
    this.notification = null;
    this.notificationIcon = null;
    this.keyboard = null;
    this.successIcon = null;
    this.cursor = null;

    this.animationTimeline = new TimelineMax({ 
      paused: true,
      smoothChildTiming: true,
      onComplete: () => setTimeout(() => {
        if (this.mounted) {
          this.setState({ active: false });
        }
      }, 1000) 
    });
    
    this.restartAnimationTimeline = new TimelineMax({ 
      paused: true,
      onComplete: () => this.animationTimeline.restart().currentLabel('documentEnter') 
    });
  }

  componentDidMount() {
    this.mounted = true;
    this.animationTimeline
      .from(this.background, 0.6, {scale: 0, ease: Elastic.easeOut.config(1.1, 1.5)}, '+=0.5')
      .addLabel('documentEnter')
      // document sign
      .from(this.document, 1, { top: '200%', ease: Elastic.easeOut.config(2, 4) }, 'documentEnter')
      .from(this.mouseCursor, 0.8, { top: '110%', ease: Power4.easeOut }, '-= 0.3')
      .to(this.mouseCursor, 0.15, { scale: .9, ease: Power4.easeOut }, '+=0.1')
      .addLabel('mouseClick')
      .to(this.mouseCursor, 0.15, { scale: 1, ease: Power4.easeOut })
      .to(this.documentTick, .3, {scale: 1, ease: Elastic.easeOut.config(2.1, 1.5)}, 'mouseClick')
      .to(this.mouseCursor, 0.8, { x: '60px', y: '35px', ease: Power4.easeOut })
      .addLabel('documentExit', '-=0.3')
      .to(this.mouseCursor, 0.8, { top: '110%', ease: Power4.easeOut }, 'documentExit')
      .to(this.document, 0.7, { top: '-100%', ease: Elastic.easeIn.config(2, 1) }, 'documentExit')
      
      // phone notification
      .addLabel('phoneEnter', '-=0.2')
      .fromTo(this.phone, 0.7, { top: '-300%', left: '50%' }, { top: '57%', ease: Elastic.easeOut.config(2, 3.5)}, 'phoneEnter')
      .fromTo(this.phoneShadow, 0.7, { x: '-50%' }, { scale: 1, ease: Elastic.easeOut.config(5, 6) }, 'phoneEnter')
      .addLabel('notificationEnter')
      .to(this.phone, 0.4, { left: '65%', rotation: '30', ease: Power4.easeOut }, 'notificationEnter')
      .to(this.phoneShadow, 0.4, { x: '-35%', ease: Power4.easeOut }, 'notificationEnter')
      .to(this.notification, 0.5, { scale: 1, ease: Elastic.easeOut.config(1.5, 2) }, 'notificationEnter')
      .from(this.notificationIcon, 0.5, { top: '-100%', ease: Elastic.easeOut.config(2, 3) }, 'notificationEnter+=0.2')
      .to(this.notificationIcon, 0.5, { scale: 1, ease: Elastic.easeOut.config(1.5, 2) }, 'notificationEnter+=0.2')
      .addLabel('notificationExit', '+=0.8')
      .to(this.notification, 0.5, { scale: 0, opacity: 0, ease: Elastic.easeOut.config(1.5, 2) }, 'notificationExit')
      .to(this.notificationIcon, 0.3, { scale: 0, opacity: 0, top: '-30%', ease: Elastic.easeOut.config(1.5, 2) }, 'notificationExit-=0.1')
      .to(this.phoneShadow, 0.4, { x: '-50%', bottom: '65px', ease: Power4.easeOut }, 'notificationExit+=0.1')
      .to(this.phone, 0.4, { left: '50%', top: '50%', rotation: '0', ease: Power4.easeOut }, 'notificationExit+=0.1')
      
      //keyboard
      .fromTo(this.keyboard, 0.4, { y: '300%', x: '-50%', left: '50%'}, { y: '0%', x: '-50%', left: '50%', ease: Elastic.easeOut.config(1.5, 2) })
      .fromTo(this.cursor, 0.4, { y: '300%', x: '-50%', left: '50%'}, { y: '0%', x: '-50%', left: '60%', top: '47%', ease: Elastic.easeOut.config(1.5, 2) })
      .to(this.cursor, 0.1, { scale: .9, ease: Power4.easeIn }, '-=0.1')
      .to(this.cursor, 0.1, { scale: 1, ease: Power4.easeOut })
      .to(this.cursor, 0.3, { left: '47.5%', top: '59%', ease: Power4.easeOut })
      .to(this.cursor, 0.1, { scale: .9, ease: Power4.easeIn }, '-=0.1')
      .to(this.cursor, 0.1, { scale: 1, ease: Power4.easeOut })
      .to(this.cursor, 0.4, { top: '47%', ease: Power4.easeOut }, '+=0.1')
      .to(this.cursor, 0.1, { scale: .9, ease: Power4.easeIn }, '-=0.1')
      .to(this.cursor, 0.1, { scale: 1, ease: Power4.easeOut })
      .to(this.cursor, 0.3, { left: '53.5%', top: '53%', ease: Power4.easeOut })
      .to(this.cursor, 0.1, { scale: .9, ease: Power4.easeIn }, '-=0.1')
      .to(this.cursor, 0.1, { scale: 1, ease: Power4.easeOut })
      .addLabel('keyboardExit', '+=0.3')
      .to(this.cursor, 0.4, { top: '100%', ease: Power4.easeOut }, 'keyboardExit')
      .to(this.keyboard, 0.8, { top: '100%', ease: Power4.easeOut }, 'keyboardExit')
      .fromTo(this.successIcon, 0.3, { x: '-50%', left: '50%', y: '-50%', top: '50%' }, { scale: 1, x: '-50%', left: '50%', y: '-50%', top: '50%', ease: Elastic.easeOut.config(1.5, 2) })
      .addLabel('phoneExit', '+=1')
      .to(this.phone, 1, { top: '-100%', ease: Elastic.easeIn.config(2, 1) }, 'phoneExit')
      .to(this.phoneShadow, 0.8, { scale: 0, ease: Elastic.easeIn.config(2, 1) }, 'phoneExit')
      .to(this.successIcon, 0.1, { scale: 0, ease: Power4.easeOut }, 'phoneExit+=1')
      
      // final static state
      .addLabel('end')
      .fromTo(this.phone, 0.7, { top: '-300%' }, { top: '57%', rotation: '30', left: '65%', ease: Elastic.easeOut.config(2, 3.5)}, 'end')
      .to(this.phoneShadow, 0.7, { scale: 1, x: '-35%', bottom: '30px', ease: Elastic.easeOut.config(5, 6) }, 'end')
      .to(this.notification, 0.5, { scale: 1, opacity: 1, ease: Elastic.easeOut.config(1.5, 2) }, 'end+=0.5')
      .to(this.notificationIcon, 0.5, { scale: 1, opacity: 1, top: '0%', ease: Elastic.easeOut.config(1.5, 2) }, 'end+=0.7')
    ;

    this.restartAnimationTimeline
      .addLabel('restart')
      .to(this.phone, 0.5, { top: '-100%', ease: Elastic.easeOut.config(1, 1)}, 'restart')
      .to(this.phoneShadow, 0.4, { scale: 0, x: '-35%', ease: Elastic.easeOut.config(5, 6) }, 'restart')
      .to(this.notification, 0.5, { scale: 0, opacity: 0, ease: Elastic.easeOut.config(1.5, 2) }, 'restart+=0.2')
      .to(this.notificationIcon, 0.3, { scale: 0, opacity: 0, ease: Elastic.easeOut.config(1.5, 2) }, 'restart+=0.1')
    ;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onAssetLoad() {
    this.setState({ loadedCount: this.state.loadedCount + 1 })
    setTimeout(() => (this.state.loadedCount === this.assetsCount) ? this.play() : null, 1);
  }

  play() {
    this.setState({ active: true });
    this.animationTimeline.play();
  }
  
  restart() {
    if (!this.state.active) {
      this.setState({ active: true });
      this.restartAnimationTimeline.restart();
    }
  }
  
  render() {
    return (
      <StyledMainProcessIcon 
        onClick={() => this.restart()} 
        onMouseEnter={() => this.restart()} 
        {...this.props}>
        <div ref={ref => this.background = ref} className="background-circle item"></div>
        <div className="inner-circle">
          <div ref={ref => this.document = ref} className="document item center">
            <DocumentIcon onLoad={() => this.onAssetLoad()}></DocumentIcon>
            <div ref={ref => this.documentTick = ref} className="document-tick item">
              <DocumentTickIcon onLoad={() => this.onAssetLoad()}></DocumentTickIcon>
            </div>  
          </div>
          <div ref={ref => this.mouseCursor = ref} className="mouse-cursor item">
            <MouseCursorIcon onLoad={() => this.onAssetLoad()}></MouseCursorIcon>
          </div>
          <div ref={ref => this.phoneShadow = ref} className="phone-shadow item">
            <PhoneShadow onLoad={() => this.onAssetLoad()}></PhoneShadow>
          </div>
          <div ref={ref => this.phone = ref} className="phone item center">
            <PhoneIcon onLoad={() => this.onAssetLoad()}></PhoneIcon>
            <div className="phone-screen item">
              <div ref={ref => this.keyboard = ref} className="keyboard item">
                <KeyboardIcon onLoad={() => this.onAssetLoad()}></KeyboardIcon>
              </div>
              <div ref={ref => this.successIcon = ref} className="success-icon item center">
                <SecureIcon onLoad={() => this.onAssetLoad()}></SecureIcon>
              </div>
            </div>
          </div>
          <div ref={ref => this.cursor = ref} className="cursor item">
            <CursorIcon onLoad={() => this.onAssetLoad()}></CursorIcon>
          </div>
          <div ref={ref => this.notification = ref} className="notification item">
            <SecureNotification onLoad={() => this.onAssetLoad()}></SecureNotification>
            <div ref={ref => this.notificationIcon = ref} className="notification-icon item">
              <SecureCircleIcon onLoad={() => this.onAssetLoad()}></SecureCircleIcon>
            </div>    
          </div>    
        </div>
      </StyledMainProcessIcon>
    )
  }
}

export default MainProcessIcon;
