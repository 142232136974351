import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import {Elastic, TimelineMax} from "gsap/TweenMax";
import StyledActionIcon from './ActionIconStyle';

export const ActionIconTypes = {
  drop: 'drop',
  send: 'send',
  sign: 'sign',
  pick: 'pick',
  fill: 'fill',
}

const DropIcon = lazy(() => import('./DocumentDropIcon/DocumentDropIcon'));
const SendIcon = lazy(() => import('./DocumentSendIcon/DocumentSendIcon'));
const SignIcon = lazy(() => import('./DocumentSignIcon/DocumentSignIcon'));
const PickIcon = lazy(() => import('./DocumentPickIcon/DocumentPickIcon'));
const FillIcon = lazy(() => import('./DocumentFillIcon/DocumentFillIcon'));

class ActionIcon extends React.Component {

  constructor(props) {
    super(props);
    this.iconRef = null;
    this.background = null;
    this.backgroundAnimation = new TimelineMax({ 
      paused: true,
    });
  }

  componentDidMount() {
    this.backgroundAnimation
      .fromTo(this.background, 0.5, { scale: 0 }, { scale: 1, ease: Elastic.easeOut.config(3, 3) }, '+=1')
    ;
    this.backgroundAnimation.play();
  }
  
  render() {
    let Icon = null;
    switch (this.props.type) {
      case ActionIconTypes.drop: Icon = DropIcon; break;
      case ActionIconTypes.send: Icon = SendIcon; break;
      case ActionIconTypes.sign: Icon = SignIcon; break;
      case ActionIconTypes.pick: Icon = PickIcon; break;
      case ActionIconTypes.fill: Icon = FillIcon; break;
      default : Icon = null;
    }

    return (
      <StyledActionIcon {...this.props}>
        <div className="background-circle" ref={ref => this.background = ref}>
          <Suspense fallback={null}>
            <Icon hovered={this.props.hovered}></Icon>
          </Suspense>
        </div>
      </StyledActionIcon>
    )
  }
}

ActionIcon.propTypes = {
  hovered: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
}

export default ActionIcon;
