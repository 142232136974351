import React from 'react';
import PrivacyStyle from './PrivacyStyle';
import PageSection from '../../components/PageSection/PageSection';

const Privacy = props => 
  <PrivacyStyle>
    <PageSection className="privacy-section">
      <div className="privacy-content">
        <h1>Skilmálar gagnavinnslu Taktikal</h1>
        <p>Notandi, hér eftir nefndur „ábyrgðaraðili“</p>
        <p>Og</p>
        <p>Taktikal ehf., kt. 540317-2080, með lögheimili að Borgartún 27, 105 Reykjavík, hér eftir nefndur „vinnsluaðili“</p>
        <p>gera með sér svofelldan vinnslusamning, í samræmi við 28. gr. reglugerðar Evrópuþingsins og ráðsins (ESB) 2016/679 frá 27. apríl 2016</p>
        <ol>
          <li>
            <p>Tilgangur vinnslu og tegundir upplýsinga</p>
            <p>Tilgangur þessara samningsákvæða er að tilgreina þær skyldur sem vinnsluaðili sinnir f.h. ábyrgðaraðila, í tengslum við þá vinnslustarfsemi sem samningurinn tekur til, sjá nánar í kafla 3.</p>
            <p>Samningsaðilar skulu bundnir af öllum viðeigandi lagaákvæðum sem varða vinnslu persónuupplýsinga hjá þeim og þá sérstaklega reglugerð Evrópuþingsins og ráðsins (ESB) 2016/679, frá 27. apríl 2016, um vernd einstaklinga í tengslum við vinnslu persónuupplýsinga og um frjálsa miðlun slíkra upplýsinga og niðurfellingu tilskipunar 95/46/EB (almennu persónuverndarreglugerðinni) sem kom til framkvæmda 25. maí 2018.</p>
            <p>Þær persónuupplýsingar sem vinnsla þessi nær yfir teljast í flestum tilvikum almennar.</p>
          </li>
          <li>
            <p>Lýsing á þeirri vinnslu sem samið er um að vinnsluaðili sinni</p>
            <p>Vinnsluaðila er heimilt að vinna, f.h. ábyrgðaraðila, þær persónuupplýsingar sem eru honum nauðsynlegar til að veita þá þjónustu sem Taktikal býður uppá.</p>
            <p>Eðli þeirrar vinnslustarfsemi sem hér um ræðir er auðkenning, upplýsinga söfnum fyrir ferla (Flow) ábyrgðaraðila, að búa til og eða fylla út skjöl,  undirritun skjala og samskipti við viðskiptavini ábyrgaðaraðila.</p>
            <p>Vinnsluaðila er heimilt að vinna með eftirfarandi flokka af skráðum einstaklingum:</p>
            <ul>
              <li>Viðskiptavinur ábyrgðaraðila, nafn, kennitala, heimilisfang, netfang og símanúmer auk annara upplýsinga sem viðskiptavinur ábyrgðaraðila lætur af hendi í ferli (Flow)</li>
              <li>Starfsmaður ábyrgðaraðila, Nafn, netfang og notkun á kerfum Taktikal</li>
            </ul>
          </li>
          <li>
            <p>Skyldur vinnsluaðila gagnvart ábyrgðaraðila</p>
            <p>Vinnsluaðili hefur undirritað vinnslusamninga við undirvinnsluaðila sem kunna að meðhöndla persónuupplýsingar fyrir okkar hönd</p>
            <p>Vinnsluaðili skal:</p>
            <ul>
              <li>Eingöngu vinna persónuupplýsingar í samræmi við tilgang vinnslunnar, skv. samningi þessum.</li>
              <li>Vinna persónuupplýsingar samkvæmt fyrirmælum ábyrgðaraðila, sem fylgja samningi þessum. Í þeim tilvikum þegar vinnsluaðili telur að fyrirmæli ábyrgðaraðila samrýmist ekki almennu persónuverndarreglugerðinni eða öðrum viðeigandi lagaákvæðum sem varða vinnslu persónuupplýsinga ber honum að tilkynna ábyrgðaraðilanum slíkt án tafar. Þá skal vinnsluaðili gera ábyrgðaraðila viðvart ef vinnsluaðila er skylt samkvæmt lögum að flytja persónupplýsingar til þriðju landa eða alþjóðastofnana, nema lög banni að upplýst sé um slíkt.</li>
              <li>Tryggja trúnað um vinnslu þeirra persónuupplýsinga sem þessi samningur tekur til</li>
              <li>Tryggja að þeir starfsmenn sem hafi aðgang að persónuupplýsingum í tengslum við framkvæmd samningsins hafi undirritað trúnaðaryfirlýsingu eða séu bundnir þagnarskyldu samkvæmt lögum og að þeir fái viðeigandi þjálfun í vernd persónuupplýsinga.</li>
              <li>Gæta þess að, vörur, forrit og þjónusta séu hönnuð með innbyggða og persónuvernd að leiðarljósi</li>
              <li>
                <p>Notkun á undirvinnsluaðila</p>
                <p>Vinnsluaðila er heimilt að semja við annan aðila („undirvinnsluaðila“) um að framkvæma tilteknar vinnsluaðgerðir. Áður en ætlaðar breytingar taka gildi, bæði þegar bætt er við undirvinnsluaðila og þegar gerðar eru breytingar á þeim undirvinnsluaðilum sem þegar eru notaðir, eða þegar um að ræða viðbætur eða breytingu á gildandi fyrirkomulagi vinnsluaðgerða, skal vinnsluaðili upplýsa ábyrgðaraðila skriflega um breytingarnar. Þar skal sérstaklega taka fram hvaða vinnsluaðgerðir undirvinnsluaðilinn hyggst taka að sér, nafn og samskiptaupplýsingar undirvinnsluaðilans ásamt dagsetningu samnings. Í viðauka 1 er að finna lista yfir núverandi undirvinnsluaðila.</p>
              </li>
              <li>
                <p>Réttur hinna skráðu til upplýsinga.</p>
                <p>Ábyrgðaraðili ber ábyrgð á því að veita hinum skráðu upplýsingar (fræðslu) um vinnslustarfsemina fyrir eða um leið og vinnsla hefst, í samræmi við ákvæði almennu persónuverndarreglugerðarinnar um upplýsingar sem ber að veita hinum skráða, sbr. m.a. 13. og 14. gr. Hennar.</p>
              </li>
              <li>
                <p>Veiting réttinda til handa hinum skráðu</p>
                <p>Að því marki sem hægt er ber vinnsluaðila að aðstoða ábyrgðaraðila við að sinna þeirri skyldu sinni að bregðast við erindum skráðra einstaklinga vegna réttinda þeirra, svo sem vegna aðgangsréttar, réttar til leiðréttingar og eyðingar upplýsinga og til að andmæla vinnslu eða takmarka hana, flutningsréttar og réttar til að þurfa ekki að sæta sjálfvirkri ákvarðanatöku, þ.m.t. notkun persónusniða. Þegar hinn skráði leggur fram beiðni um að neyta réttinda sinna hjá vinnsluaðila skal vinnsluaðilinn áframsenda slíka beiðni án tafar til ábyrgðaraðila.</p>
              </li>
              <li>
                <p>Tilkynning vegna öryggisbrots</p>
                <p>Vinnsluaðili skal tilkynna ábyrgðaraðila um hvers konar öryggisbrot eigi síðar en 24 klukkustundum eftir að hann verður var við brotið. Með tilkynningunni skulu fylgja hver þau skjöl eða gögn sem nauðsynleg eru til þess að ábyrgðaraðili geti tilkynnt um brotið til viðeigandi eftirlitsstofnunar (Persónuverndar).</p>
                <p>Upplýsingar sem sendar eru hinum skráðu skulu vera á skýru og einföldu máli og lýsa í það minnsta:</p>
                <ul>
                  <li>Eðli öryggisbrotsins, þ.m.t., þegar það á við, flokkum og gróflega áætluðum fjölda þeirra einstaklinga sem verða fyrir áhrifum af brotinu og flokkum og magni þeirra gagna (e. records) sem um ræðir</li>
                  <li>Nafni og samskiptaupplýsingum persónuverndarfulltrúa eða annars tengiliðar þar sem hægt er að nálgast frekari upplýsingar</li>
                  <li>hverjar séu líklegar afleiðingar öryggisbrotsins</li>
                  <li>til hvaða aðgerða hafi verið gripið eða lagt til að gripið verði til til að bregðast við brotinu, þ.m.t., þar sem það á við, aðgerða til að draga úr áhrifum brotsins á einstaklinga</li>
                  <li>til hvaða aðgerða einstaklingarnir geti gripið til að lágmarka tjón sitt</li>
                </ul>
              </li>
              <li>
                <p>Aðstoð gagnvart ábyrgðaraðila við að uppfylla skilyrði almennu persónuverndarreglugerðarinnar</p>
                <p>Vinnsluaðili skal aðstoða ábyrgðaraðila við að framkvæma mat á áhrifum á persónuvernd. Vinnsluaðili skal aðstoða ábyrgðaraðila við að uppfylla ákvæði reglugerðarinnar um fyrirframsamráð við eftirlitsyfirvaldið (Persónuvernd).</p>
              </li>
              <li>
                <p>Öryggisráðstafanir</p>
                <p>Vinnsluaðili skal innleiða eftirfarandi öryggisráðstafanir:</p>
                <ul>
                  <li>innleiðingu tæknilegra og skipulagslegra ráðstafana sem ætlað er að tryggja viðvarandi trúnað, uppitíma, rekstraröryggi og álagsþol vinnslukerfa og þjónustu</li>
                  <li>stýringu aðgengis einstaklinga að starfstöð okkar og öryggisvörslu stýringu aðgengis starfsmanna og annarra að kerfum sem hafa að geyma persónu­upplýsingar</li>
                  <li>að tryggja að þjónustuaðilar okkar sem hafa aðgang að persónuupplýsingum notenda, hafi gert viðeigandi verndarráðstafanir til að tryggja öryggi persónuupplýsinga</li>
                  <li>dulkóðun persónuupplýsinga notenda</li>
                </ul>
              </li>
              <li>
                <p>Hvað verður um persónuupplýsingar við lok vinnslu</p>
                <p>Þegar þjónustu lýkur samkvæmt samningi þessum samþykkir vinnsluaðili að:</p>
                <p>Þegar upplýsingum er skilað þarf einnig að eyða öllum afritum af persónugreinanlegum upplýsingum sem finna má í kerfum vinnsluaðila.</p>
                <p>Á meðan undirritunar ferli á sér stað fyrir viðskiptavin ábyrgðaraðila þá geymir Taktikal persónugreinanleg gögn. Gögnum eru eytt eftir að viðskiptavinur hefur undirritað skjal og því hefur verið afhent til ábyrgðaraðila eða eftir 10 daga ef undirritun fer ekki fram, eftir því hvort gerist á undan.</p>
                <p>Í aðgerðayfirliti í kerfi Taktikal eru geymdar upplýsingar um skráningar. Þar er geymt hvenær viðskiptavinur auðkennir eða undirritar. Í þeim aðgerðum er símanúmer vistað. Eina vinnslan á þeim gögnum er notkun á kerfinu á tímabili. Í aðgerðayfirliti er geymdur fæðingadagur viðskiptavinar</p>
              </li>
              <li>
                <p>Skrá yfir vinnslustarfsemi</p>
                <p>Vinnsluaðili skal halda skrá yfir alla vinnslustarfsemi sem fram fer fyrir ábyrgðaraðila.</p>
                <p>Í henni skal koma fram eftirfarandi:</p>
                <p>heiti og samskiptaupplýsingar vinnsluaðila, eins eða fleiri, og sérhvers ábyrgðaraðila sem vinnsluaðilinn starfar í umboði fyrir; flokkar vinnslu sem fram fer fyrir hönd hvers ábyrgðaraðila.</p>
              </li>
              <li>
                <p>Skjölun vegna sönnunar á reglufylgni</p>
                <p>Vinnsluaðili skal útvega ábyrgðaraðila öll nauðsynleg skjöl til að hann geti sýnt fram á reglufylgni og til að ábyrgðaraðili eða úttektaraðili geti framkvæmt úttektir, þ.m.t. skoðanir, og veita aðstoð við slíkar úttektir.</p>
              </li>
            </ul>
          </li>
          <li>
            <p>Skyldur ábyrgðaraðila gagnvart vinnsluaðila</p>
            <p>Ábyrgðaraðili skal:</p>
            <p>afhenda vinnsluaðila þau gögn sem nefnd eru í kafla 2;</p>
            <p>skrá skriflega öll fyrirmæli varðandi vinnsluna sem beint er að vinnsluaðila;</p>
            <p>tryggja, fyrir og á meðan á vinnslu stendur, að hann starfi í samræmi við þær kröfur sem gerðar eru til hans samkvæmt almennu persónuverndarreglugerðinni, og;</p>
            <p>hafa yfirumsjón með vinnslunni, þ.m.t. með því að framkvæma úttektir og skoðanir hjá vinnsluaðilanum.</p>
          </li>
          <li>
            <p>Gildistími samnings</p>
            <p>Samningur þessi tekur gildi þegar ábyrgðaraðili samþykkir skilmála Taktikal við skráningu að þjónustunni og gildir á meðan ábyrgðaraðili nýtir sér þjónustu Taktikal með einhverjum hætti.</p>
          </li>
        </ol>
        <br></br>

        <p>Viðauki 1</p>
        <p>Persónuverndarstefnur og rafrænt samþykktir vinnslusamningar við undirvinnsluaðila:</p>

        <table>
          <thead>
            <tr>
              <th>Nafn</th>
              <th>Lýsing</th>
              <th>Dagsetning</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Microsoft Azure</td>
              <td>
                <p>Hýsir þjónustur og gagnagrunna Taktikal.</p>
                <p>Microsoft uppfyllir persónuverndarlögin og við höfum undirritað vinnslusamning við þau.</p>
                <p><a href="https://www.microsoft.com/en-us/licensing/product-licensing/products" target="_blank" rel="noopener noreferrer">Skilmálar (OST)</a></p>
                <p><a href="https://privacy.microsoft.com/en-GB/privacystatement" target="_blank" rel="noopener noreferrer">Persónuverndastefna</a></p>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Mailgun</td>
              <td>
                <p>Utanumhald á  tölvupóstsendingum til starfsmanna og endanotanda</p>
                <p><a href="https://www.mailgun.com/privacy-policy" target="_blank" rel="noopener noreferrer">Persónuverndastefna</a></p>
                <p><a href="https://www.mailgun.com/gdpr" target="_blank" rel="noopener noreferrer">GDPR</a></p>
                <p><a href="https://www.mailgun.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a></p>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Auth0</td>
              <td>
                <p>Aðgangsstýringakerfi að vörum og þjónustu Taktikal.</p>
                <p><a href="https://auth0.com/privacy" target="_blank" rel="noopener noreferrer">Persónuverndastefna</a></p>
                <p><a href="https://auth0.com/docs/compliance/gdpr" target="_blank" rel="noopener noreferrer">GDPR</a></p>
                <p><a href="https://auth0.com/docs/compliance/gdpr/data-processing" target="_blank" rel="noopener noreferrer">Vinnslusamningur DPA</a></p>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Auðkenni</td>
              <td>
                <p>Auðkenning og undirritun með rafrænum skilríkjum</p>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Dokobit</td>
              <td>
                <p>Rafræn undirritun skjala í kerfum Taktikal.</p>
                <p><a href="https://www.dokobit.com/compliance/privacy-policy" target="_blank" rel="noopener noreferrer">Persónuverndastefna</a></p>
                <p><a href="https://www.dokobit.com/compliance" target="_blank" rel="noopener noreferrer">GDPR</a></p>
                <p><a href="https://www.dokobit.com/compliance/dpa" target="_blank" rel="noopener noreferrer">Vinnslusamningur DPA</a></p>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Advania</td>
              <td>
                <p>Aðgangur að þjóðskrá í gegnum vefþjónustur</p>
                <p><a href="https://www.advania.is/default.aspx?pageid=b04f56cb-5d44-4f6d-99b1-5df112f4d0a9" target="_blank" rel="noopener noreferrer">Persónuverndastefna</a></p>
              </td>
              <td></td>
            </tr>
            <tr>
              <td>Sentry</td>
              <td>
                <p>Eftirlitskerfi á villum í kerfum Taktikal</p>
                <p><a href="https://sentry.io/privacy/" target="_blank" rel="noopener noreferrer">Persónuverndastefna</a></p>
                <p><a href="https://sentry.io/security/" target="_blank" rel="noopener noreferrer">GDPR</a></p>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </PageSection>
  </PrivacyStyle>

export default Privacy;
