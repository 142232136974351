import React from 'react';
import ComingSoonStyle from './ComingSoonStyle';
import MainShadow from './../../assets/main_shadow.svg';
import MainSkyline from './../../assets/main_skyline.svg';

const CoomingSoonContent = {
  subheader: 'Coming soon...'
}

const CoomingSoon = props => 
  <ComingSoonStyle backgroundShadow={MainShadow} background={MainSkyline}>
    <div className="background-shadow">
    </div>
    <div className="background"></div>
    <div className="content">
      <div className="subheader">{CoomingSoonContent.subheader}</div>
    </div>
  </ComingSoonStyle>

export default CoomingSoon;
