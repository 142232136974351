import styled from 'styled-components';

const MainProcessIconStyle = styled.div`
  width: 518px;
  height: 518px;
  position: relative;

  .inner-circle {
    position: absolute;
    top: 0; left: 0;
    width: 518px;
    height: 518px;
    border-radius: 50%;
    overflow: hidden;
  }

  .item {
    position: absolute;
  }

  .center {
    top: 50%; left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .background-circle {
    background: ${props => props.theme.color.iconBackground};
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .document-tick {
    top: 74.8%;
    left: 21.6%;
    transform: scale(0);
  }
  
  .mouse-cursor {
    top: 67.6%;
    left: 38.5%;
  }

  .phone-screen {
    overflow: hidden;
    width: 40%;
    height: 48%;
    top: 50%; left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .phone-shadow {
    bottom: 30px;
    left: 50%; 
    transform: scale(0);
  }

  .notification {
    top: 9%;
    left: 20%;
    transform: scale(0);
  }

  .notification-icon {
    top: -1%;
    left: 51%;
    transform: scale(0);
  }

  .keyboard {
    top: 36%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .cursor {
    top: 100%;
  }

  .success-icon {
    width: 53px;
    height: 61px;
    transform: scale(0);
  }
`;

export default MainProcessIconStyle;
