import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import ComingSoon from './pages/ComingSoon/ComingSoon';

let isLanding = window.location.pathname.replace(/\//g, '') === 'landing';
const Page = isLanding ? App : ComingSoon;

ReactDOM.render(
  <Page/>,
  document.getElementById('root')
);

