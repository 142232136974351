import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import themes from './utils/theme';
import * as Scroll from 'react-scroll';

import Header from './components/Header/Header';
import Main from './pages/Main/Main';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Privacy from './pages/Privacy/Privacy';
import Footer from './components/Footer/Footer';

export const history = createBrowserHistory();

class App extends Component {
  initialState = {
    currentRoute: '',
    privacyActive: false,
    items: [
      {
        order: 0,
        title: 'How it works',
        active: false,
        link: 'how-it-works'
      },
      {
        order: 1,
        title: 'About',
        active: false,
        link: 'about'
      },
      {
        order: 2,
        title: 'Try it out',
        link: 'contact',
        primary: true
      },
      // {
      //   order: 3,
      //   title: 'Sign in',
      //   target: '_blank',
      //   link: 'https://google.is',
      //   secondary: true
      // }
    ]
  };
  state = {items: [], privacyActive: false};

  componentDidMount() {
    const initialRoute = history.location.pathname.replace('/', '');
    const initialOrder = this.initialState.items.find(item => item.link === initialRoute);
    this.setState({currentRoute: initialRoute, ...this.initialState}, () => {
      if (initialRoute === 'privacy') {
        history.push('privacy');
        return;
      }
      if (initialOrder && initialOrder.order) {
        setTimeout(()=> {
          this.onMenuClick(undefined, initialOrder.order);
        }, 100);
      }
    });

    history.listen((location) => {
      this.setState({currentRoute: location.pathname}, () => {
        this.onRouteChange(location.pathname);
      });
    });
  }

  onMenuClick(e, itemOrder) {
    const newState = {...this.initialState};
    newState.items.forEach(item => {
      item.active = false;
      if (item.order === itemOrder) {
        if (item.target !== undefined) return;
        item.active = true;
        
        this.setState({...newState}, () => {
          history.push(item.link);
        });
        if (e) e.preventDefault();
      }
    });
  }

  onHomeClick(e) {
    e.preventDefault();
    history.push('/');
  }

  onRouteChange(route) {
    const path = route.replace(/\//g, '');

    if (path === 'privacy' && !this.state.privacyActive) {
      this.onMenuClick(undefined, -1);
      this.setState({ privacyActive: true });
      return;
    } else {
      this.setState({ privacyActive: false });
    }

    let element = document.getElementById(path);
    if (element === null) element = document.body;

    let elementTop = element.offsetTop;
    Scroll.animateScroll.scrollTo(elementTop - themes.main.header.height);
  }

  render() {
    const { items, privacyActive } = this.state;
    return (
      <ThemeProvider theme={themes.main}>
        <Router history={history}>
            <div>
              <Header 
                onHomeClick={(e) => this.onHomeClick(e)} 
                onMenuClick={(e, order) => this.onMenuClick(e, order)} 
                menuItems={items}>
              </Header>
              <CSSTransition
                in={!privacyActive}
                timeout={300}
                unmountOnExit
                classNames="content-fade"
              >
                <div className="content-fade">
                  <Main></Main>
                  <div id="how-it-works"><HowItWorks></HowItWorks></div>
                  <div id="about"><About></About></div>
                  <div id="contact"><Contact></Contact></div>
                </div>
              </CSSTransition>
              <CSSTransition
                in={privacyActive}
                timeout={300}
                unmountOnExit
                onEntered={() => window.scrollTo(0,0)}
                classNames="content-fade"
              >
                <div className="content-fade">
                  <Privacy></Privacy>
                </div>
              </CSSTransition>
              <Footer></Footer>
            </div>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
