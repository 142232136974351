import styled from 'styled-components';

const InstructionStyle = styled.div`
  width: 100%; 

  .header-block {
    font-weight: 600;
    line-height: 50px;
    font-size: 21px;
    text-align: center;
    letter-spacing: 0.1em;
    color: ${ props => props.theme.color.primary};
    text-align: center;
    text-transform: uppercase;
    height: 50px;
    background: ${props => props.theme.color.lightText};
    border-radius: 5px;
    margin-bottom: 30px;
  }

  .instructions-grid {
    display: flex;
    justify-content: space-between;
  }

  .instruction-block-wrap {
    width: calc(50% - 15px);
  }

  .instruction-block {
    position: relative;
    border-radius: 5px;
    background: ${props => props.theme.color.primaryLight};
    padding: 40px;
    min-height: 385px;
  }

  .step {
    height: 105px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }

  .step-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }

  .step-content-wrap {
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .step-content {
    font-weight: 300;
    line-height: 26px;
    font-size: 16px;
  }

  .step-icon {
    width: 88px;
    height: 88px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: ${props => props.theme.content.container.large}px) {
    .header-block {
      font-weight: 600;
      font-size: 18px;
    }

    .instructions-grid {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
  
    .instruction-block-wrap {
      margin-bottom: 30px;
      width: 100%;
      &:nth-last-child(1) {
        margin-bottom: 0px;
      }
    }
    
    .instruction-block {
      padding: 10px;
      padding-right: 15px;
      padding-bottom: 40px;
    }

    .step {
      justify-content: flex-start;
      height: auto;
      margin: 20px 0;
    }

    .step-content-wrap {
      margin-left: 15px;
    }

    .step-icon {
      top: 0%;
      transform: translateY(0%);
    }
  }

`;

export default InstructionStyle;
