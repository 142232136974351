import React from 'react';
import PropTypes from 'prop-types';
import ActionIcon from '../ActionIcon/ActionIcon';
import withViewportAnimation from './../ViewportAnimation/ViewportAnimation';
import StyledInstructions from './InstructionsStyle';

const InstructionStep = props =>
  <div className="step">
    <div className="step-icon">
      <ActionIcon type={props.icon} hovered={props.hovered}></ActionIcon>
    </div>
    <div className="step-content-wrap">
      <div className="step-title">{props.title}</div>  
      <div className="step-content">{props.content}</div>  
    </div>
  </div>

const InstructionStepWrap = props =>
  <InstructionStep
    id={props.id} 
    icon={props.icon} 
    title={props.title} 
    content={props.content} 
    hovered={props.active}>
    once={true},
  </InstructionStep>

const InstructionBlockBox = props =>
  <React.Fragment>
    {props.steps.map(step => 
      <div key={step.id} 
        className="step"
        onClick={() => props.onItemMouseClick(step.id)}
        onMouseEnter={() => props.onItemMouseEnter(step.id)}
        onMouseLeave={() => props.onItemMouseLeave(step.id)}>
        {
          withViewportAnimation(InstructionStepWrap, {direction: 'vertical', from: 'top', offset: 30, delay: Math.floor(step.id/2) * 0.1})(step)
        }
      </div>
    )}
  </React.Fragment>

const InstructionBlock = props => 
  <div className="instruction-block-wrap">
    <div className="header-block" >{props.header}</div>
    <div className="instruction-block">
      <InstructionBlockBox {...props}></InstructionBlockBox>
    </div>
  </div>

const Instructions = props =>
  <StyledInstructions>
    <div className="instructions-grid">
      <InstructionBlock 
        header={props.client.header} 
        steps={props.client.steps}
        onItemMouseClick={(id) => props.onItemMouseClick(id)}
        onItemMouseEnter={(id) => props.onItemMouseEnter(id)}
        onItemMouseLeave={(id) => props.onItemMouseLeave(id)}>
      </InstructionBlock>
      <InstructionBlock 
        header={props.staff.header} 
        steps={props.staff.steps}
        onItemMouseClick={(id) => props.onItemMouseClick(id)}
        onItemMouseEnter={(id) => props.onItemMouseEnter(id)}
        onItemMouseLeave={(id) => props.onItemMouseLeave(id)}>
      </InstructionBlock>
    </div>
  </StyledInstructions>

Instructions.propTypes = {
  client: PropTypes.object,
  staff: PropTypes.object
}

export default Instructions;
