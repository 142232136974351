import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {Grid} from '@material-ui/core';
import FooterLogo from './../../assets/footerLogo.svg';
import {PageContainer} from './../PageSection/PageSectionStyle';
import StyledFooter from './FooterStyle';

const FooterData = {
  logoLink: 'https://taktikal.is/',
  contact: 'mailto:hallo@taktikal.is/',
  termsAndConditions: 'privacy'
}

class Footer extends Component {
  render() {
    const { history } = this.props;
    
    return (
      <StyledFooter {...this.props}>
        <PageContainer>
          <Grid container className="row" justify="center" direction="column">
            <Grid container item justify="space-between">
              <Grid item className="footer-logo">
                <a href={FooterData.logoLink} target="_blank" rel="noopener noreferrer">
                  <img src={FooterLogo} alt="Footer-logo"></img>
                </a>
              </Grid>
              <Grid item>
                <div className="navigation">
                  <a 
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(FooterData.termsAndConditions);
                    }}
                    href={FooterData.termsAndConditions} 
                    rel="noopener noreferrer"
                  >
                    Terms and conditions
                  </a>
                  <span>/</span> 
                  <a href={FooterData.contact} target="_blank" rel="noopener noreferrer">Contact</a>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </PageContainer>
      </StyledFooter>
    );
  }
}

export default withRouter(Footer);
