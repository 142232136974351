import React from 'react';
import PropTypes from 'prop-types';
import TrackVisibility from 'react-on-screen';
import ViewportAnimationStyle from './ViewportAnimationStyle';

const StyledAnimation = props => 
  <ViewportAnimationStyle 
    className={
      (props.isVisible ? 'visible' : 'hidden') + 
      (props.direction === 'vertical' ? ' vertical' : ' horizontal')} {...props}>
    {props.children}
  </ViewportAnimationStyle>

StyledAnimation.propTypes = {
  from: PropTypes.string,
  direction: PropTypes.string,
  offset: PropTypes.number,
  delay: PropTypes.number,
  duration: PropTypes.number,
}

const withViewportAnimation = (Component, animationProps) => props =>
  <TrackVisibility partialVisibility once={props.once ? props.once : true}>
    <StyledAnimation {...animationProps}>
      <Component {...props}></Component>
    </StyledAnimation>
  </TrackVisibility>

export default withViewportAnimation;
